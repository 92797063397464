import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "@firebolt-dev/jsx/jsx-runtime";
import {cloneElement} from 'react';
import {useMDXComponents} from 'firebolt';
const MDXLayout = function MDXWrapper({children}) {
  const components = useMDXComponents();
  return cloneElement(children, {
    components
  });
};
function _createMdxContent(props) {
  const _components = {
    code: "code",
    h1: "h1",
    p: "p",
    pre: "pre",
    span: "span",
    ...props.components
  }, {Meta} = _components;
  if (!Meta) _missingMdxReference("Meta", true);
  return _jsxs(_Fragment, {
    children: [_jsx(Meta, {
      title: "Getting Started: Deployment",
      description: "Learn how to deploy a new Firebolt app."
    }), "\n", _jsx(_components.h1, {
      children: "Deployment"
    }), "\n", _jsx(_components.p, {
      children: "When you're ready to deploy your app to production you can build it with the following command"
    }), "\n", _jsx(_components.pre, {
      className: "shiki shiki-themes github-light github-dark",
      style: {
        "--shiki-light": "#24292e",
        "--shiki-dark": "#e1e4e8",
        "--shiki-light-bg": "#fff",
        "--shiki-dark-bg": "#24292e"
      },
      tabIndex: "0",
      children: _jsxs(_components.code, {
        children: [_jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: "npm"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: " run"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: " build"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line"
        })]
      })
    }), "\n", _jsx(_components.p, {
      children: "This creates an optimized build of your app that you can then start with:"
    }), "\n", _jsx(_components.pre, {
      className: "shiki shiki-themes github-light github-dark",
      style: {
        "--shiki-light": "#24292e",
        "--shiki-dark": "#e1e4e8",
        "--shiki-light-bg": "#fff",
        "--shiki-dark-bg": "#24292e"
      },
      tabIndex: "0",
      children: _jsxs(_components.code, {
        children: [_jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: "npm"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: " run"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: " start"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line"
        })]
      })
    })]
  });
}
export default function MDXContent(props = {}) {
  return _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  });
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
